<template>
  <div>
    <div class="shape"></div>
    <v-card
      height="400px"
      class="px-5 pb-5 aboutBackground center"
      tile
      elevation="0"
    >
      <v-row align="center" justify="center" class="">
        <v-col cols="12" md="9" align-self="center" class="">
          <h1
            class="
              text-center
              missionText
              text-h4 text-sm-h3 text-md-h2 text-lg-h2 
              font-weight-medium
              pb-2 pb-sm-0
            "
          >
            Our Mission
          </h1>
          <p class="text-center missionSubText mx-auto">
            We aim to provide a cost-effective solution with secure and reliable
            services for temperature monitoring in the workplace.
          </p>
        </v-col>
      </v-row>
    </v-card>

    <v-row align="center" justify="center" class="mb-2 mb-sm-15 mt-7">
      <v-col cols="12" sm="10" class="text-center">
        <h1
          class="
            aboutText
            mb-3 mb-sm-5
            text-h4 text-sm-h4 text-md-h3 text-lg-h2 
          "
        >
          About Us
        </h1>
        <p
          class="
            aboutSubText
            text-lg-h6 text-subtitle-1 text-left text-sm-center
            mx-5 mx-sm-0
          "
        >
          Co-Founders Andrea and Zaza began working together in 2015 when they
          teamed up on various community initiatives for Systers, a global
          organization of technical women with over 70K members. Their work
          together was constant and productive; Systers gained membership,
          active participation, and social engagement under their collaboration
          and leadership. This collaboration led to a friendship that once again
          turned into a working relationship and partnership. Their
          synchronicities and understanding of each others’ working styles have
          proven successful and innovative, measured by the accomplishments and
          successes with this company.
          <br />
          <br />
          When the trend of taking temperatures to enter an establishment became
          regular, Zaza and Andrea began working on a solution to assist
          businesses to work smarter, not harder. In March 2020, they started on
          the path to ZaaWink, recognizing a need for automation in the
          workplace.
        </p>
      </v-col>
    </v-row>
    <v-divider
      class="mx-auto"
      width="80%"
      color="#228587"
      style="border-width: 3px"
    ></v-divider>
    <v-row align="start" justify="center" class="my-6 my-sm-15">
      <v-col cols="12" sm="10" class="text-center">
        <h1
          class="
            aboutText
            mb-3 mb-sm-5
            text-h4 text-lg-h2 text-md-h3 text-sm-h4
            font-weight-medium
          "
        >
          Purpose
        </h1>
        <p
          class="
            aboutSubText
            text-lg-h6 text-subtitle-1 text-left text-sm-center
            mx-5 mx-sm-0
          "
        >
          <!-- TODO add classes to br statements -->
          We help businesses who are being asked, or required, to close or spend
          funds on new safety measures in response to COVID-19, and resorting to
          hiring staff to manually take temperatures of employees. Thermal
          scanning technologies and manual entry apps are being used to
          eliminate the manual process and automate without contact. These
          methods can be harmful to staff, lack efficient documentation,
          security, accuracy, and especially confidentiality.<br
            class="hidden-large"
          /><br class="hidden-large" />
          We offer an all-in-one temperature monitoring system plus temperature
          monitoring with smartwatches. The user’s temperature is taken at
          15-minute intervals, the temperature data is aggregated and
          anonymized. Businesses can monitor their employees’ fever levels
          through their dashboard without personal identifying information. We
          believe CONFIDENTIALITY is key. No person’s health information should
          be public knowledge. We respect the right to privacy, and so should
          employers.
        </p>
      </v-col>
    </v-row>
    <v-divider
      class="mx-auto"
      width="80%"
      color="#228587"
      style="border-width: 3px"
    ></v-divider>
    <v-row align="start" justify="center" class="my-6 my-sm-15">
      <v-col cols="12" sm="10" class="text-center">
        <h1
          class="
            aboutText
            mb-3
            text-h4 text-lg-h2 text-md-h3 text-sm-h4
            font-weight-medium
          "
        >
          ZaaWink In The News
        </h1>
        <p
          class="
            aboutSubText
            text-lg-h6 text-subtitle-1 text-left text-sm-center
            mx-5 mx-sm-0
          "
        >
          <a
            href="https://medium.com/startup-grind/10-startups-giving-reality-a-boost-92c3c015a3fd"
            target="_blank"
          >
            https://medium.com/startup-grind/10-startups-giving-reality-a-boost-92c3c015a3fd
          </a>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {

  // Used for title naming
  mounted() {  
    document.title = "About Us";  
  },  
};
</script>

<style scoped>
.shape {
  position: relative;
  overflow: hidden;
  height: 340px;
  color: linear-gradient(
    90deg,
    rgba(24, 155, 158, 1) 0%,
    rgba(14, 92, 93, 1) 44%,
    rgba(1, 3, 3, 1) 95%,
    rgba(1, 3, 3, 1) 100%
  );
}
.shape::before {
  border-radius: 46%;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(24, 155, 158, 1) 0%,
    rgba(14, 92, 93, 1) 44%,
    rgba(1, 3, 3, 1) 95%,
    rgba(1, 3, 3, 1) 100%
  );
  right: -200px;
  left: -200px;
  top: -200px;
  content: "";
  bottom: 0;
  background-clip: border-box;
}
.aboutBackground {
  position: absolute;
  top: 0;
  background: transparent;
  width: 100%;
  height: 426px;
  object-fit: cover;
}
.center {
  display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
}
.missionText {
  color: white;
  font-size: 56px;
  font-weight: 700px;
}
.missionSubText {
  color: white;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
.aboutText {
  color: #333333;
  font-size: 56px;
  font-weight: 700;
}
.aboutSubText {
  color: black;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
@media (max-width: 450px) {
  .missionSubText {
    font-size: 16px;
    line-height: 22px;
  }
  .aboutText {
    font-size: 32px !important;
  }
  .aboutSubText {
    font-size: 14px !important;
    line-height: 19px !important;
    font-family: inherit !important;
  }
}
/* Vuetify XS breakpoint */
@media (max-width: 600px) {
  .hidden-large {
    display: inline !important;
  }
}
@media (min-width: 600px) {
  .hidden-large {
    display: none !important;
  }
}
/* Vuetify SM Breakpoint */
@media (min-width: 600px) and (max-width: 960px) {
  
}
/* Vuetify MD Breakpoint */
@media (min-width: 960px) and (max-width: 1264px) {

}
/* Vuetify LG Breakpoint */
@media (min-width: 1264px) and (max-width: 1904px) {
  .missionSubText {
    width: 70%;
    font-size: 22px;
  }
}
@media (min-width: 1904px) {
  .missionSubText {
    width: 70%;
    font-size: 25px;
  }
}
</style>